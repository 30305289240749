import logo from './logo.svg';
import "./styles.scss";
import { Routes, Route } from 'react-router-dom';
import WebPage_Write  from './page/webpage_write';
import Login  from './page/login';
import WebPage_Analyse  from './page/webpage_analyse';
import WebPage_Dashboard  from './page/webpage_dashboard';
import ModelPage from "./page/MolelPage"
import AutoModelPage from "./page/AutoModelPage"
import Top100Page from "./page/Page/Top100"

import KeywoardDomain from "./page/Page/KeywoardDomain"
import { connect } from "react-redux";
import axios from "axios";
import { useState, useEffect, lazy, Suspense } from "react";
import WebPage_Show from './page/webpage_showArticle';
import CercleScore from "./composant/cercle score/cercleScore"
import RectangleScore from "./composant/rectangle score/rectanleScore"
import TwoBarScore from "./composant/TwoBars score/TwobarScore"
import WebPage from "./page/Page/WebPage/WebPageKeywoardDomain"
import TrackingKeywoard from "./page/Page/TrackingKeywoard"

import { ProSidebarProvider } from "react-pro-sidebar";
const App = ()=> {
  useEffect(() => {
    document.title = 'Emulator cell seo';
    
    
  }, []);
  const KeywoardVolume = lazy(() => import('./page/Page/KeywoardVolume'));

  return (
    <ProSidebarProvider> 
    <Routes >
      
      <Route path="test" element={<CercleScore global={100} one={100} two={100} three={100} four={100} />}  />
      <Route path='/analyse' element={<WebPage_Analyse/>}/>
      <Route path='/dashboard' element={<WebPage_Dashboard/>}/>
      <Route path='/' element={<Login/>}/>
      <Route path='/write/:ArticleId' element={<WebPage_Write/>}/>
      <Route path='/write/:ArticleId/:type' element={<WebPage_Write />} />
      <Route path="/article/:ArticleId" element={<WebPage_Show/>}/>
      <Route path="/model" element={<ModelPage/>}  />
      <Route path="/model/auto" element={<AutoModelPage/>}  />
      
      <Route path="/model/volume" element={<WebPage PageElement={KeywoardVolume}/>}  />
      <Route path="/model/domain" element={<WebPage PageElement={KeywoardDomain}/>}  />
      <Route path="/model/top" element={<WebPage PageElement={Top100Page}/>}  />
      <Route path="/tracking/word" element={<TrackingKeywoard/>}  />
      
      <Route path="*" element={<Login/>}  />
      
    </Routes>
    </ProSidebarProvider>
  );
}

export default App;
