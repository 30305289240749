import { useState, useEffect, lazy, Suspense } from "react";
import axios from "axios";
import { setSession ,setSerpApi} from "./action/index";
import { connect } from "react-redux";
import { auth_check } from "./constants/index";
import { useParams } from "react-router-dom";

// Other components like Login should be imported here

const mapStateToProps = (state) => {
  return {
    userid: state.userid,
    session: state.session,
    url: state.url,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSession: (id) => {
      dispatch(setSession(id));
    },
    
      setSerpApi: (api) => {
        dispatch(setSerpApi(api));
      }
  };
};

const Write = lazy(() => import("./Write"));
const Login = lazy(() => import('./login'));
const WebPage_Write = ({ setSession, session,setSerpApi }) => {
  const [authorized, setAuthorized] = useState(false);
  const [requestCount, setRequestCount] = useState(0);
  const [requestNumber, setRequestNumber] = useState(0);
  const [chatgpt_count, setchatgpt_count] = useState(0);
  const [Loading, setLoding] = useState(false);
  let { ArticleId,type } = useParams();
  console.log("ArticleId",ArticleId)
  console.log("type:",type)
  useEffect(() => {
    const fetchData = async () => {
      setLoding(true)
      const url = auth_check;
      const headers = {
        Authorization: "token " + session,
      };

      try {
        const response = await axios.get(url, { headers });

        setSerpApi(response?.data?.serp_state)
        setchatgpt_count(response.data.chatgpt_request)
        setLoding(false)
        setRequestCount(response.data.request_count);
        setRequestNumber(response.data.request_number);
        setAuthorized(true);
        
      } catch (error) {
        setLoding(false)
        console.error("Not authorized ", error);
        setAuthorized(false);
      }
    };

    fetchData();
  }, [session]);
  if(Loading ===true){
    return(
      <div>Loading...</div>
    )
  }
  else{
   
  return (
 
    <div>
      {authorized ? (
        <Suspense fallback={<div>Loading...</div>}>
          <Write
            paramater={ArticleId}
            request_credit={requestNumber - requestCount}
            chatgpt_count = {chatgpt_count}
            type={type}
          ></Write>
        </Suspense>
      ) : (
        <Suspense fallback={<div>Loading...</div>}>
          {<Login/>}
        </Suspense>
      )}
    </div>
  );}
};

export default connect(mapStateToProps, mapDispatchToProps)(WebPage_Write);