import React, { useState } from "react";
import { Button, Form, Container, Row, Col, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import toast, { Toaster } from "react-hot-toast";
import * as XLSX from "xlsx";
import axios from "axios";
import { FaDownload } from "react-icons/fa";
import {TrackingUpload} from "../../page/constants/index"


const UploadExcelComponent = ({ session }) => {
  const [file, setFile] = useState(null);
  const [searching, setSearching] = useState(false);
  const [processedData, setProcessedData] = useState([]); // Data for download button

  // Handle file selection
  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
    }
  };

  // Function to send file to backend
  const handleUpload = async () => {
    if (!file) {
      toast.error("يرجى تحميل ملف Excel أولاً");
      return;
    }

    setSearching(true); // Show spinner

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(TrackingUpload, formData, {
        headers: {
          Authorization: `token ${session}`,
          "Content-Type": "multipart/form-data",
        },
      });

      setProcessedData(response.data.keywords); // Store the received data
      toast.success("تم تحميل ومعالجة الملف بنجاح");
    } catch (error) {
      toast.error("حدث خطأ أثناء رفع الملف");
      console.error("Error uploading file:", error);
    } finally {
      setSearching(false);
    }
  };

  return (
    <Container
      fluid
      className="p-3 d-flex justify-content-center align-items-center"
      style={{
        backgroundColor: "#212429",
        borderRadius: "10px",
        width: "100%",
        minHeight: "100px",
      }}
    >
      <Row className="w-100 d-flex align-items-center g-2" style={{ maxWidth: "600px" }}>
        {/* File Upload Input */}
        <Col xs={12} sm={6}>
          <Form.Group controlId="form-file-upload">
            <Form.Label className="text-light">تحميل ملف Excel</Form.Label>
            <Form.Control
              type="file"
              accept=".xls,.xlsx"
              onChange={handleFileChange}
              className="bg-dark text-light"
              style={{ height: "45px" }}
            />
          </Form.Group>
        </Col>

        {/* Upload Button */}
        <Col xs={6} sm={3} className="d-flex align-items-end">
          <Button
            variant="primary"
            onClick={handleUpload}
            className="w-100"
            style={{ height: "45px", fontSize: "18px" }}
            disabled={searching}
          >
            {searching ? <Spinner animation="border" size="sm" role="status" className="me-2" /> : "تحليل"}
          </Button>
        </Col>

        {/* Download Processed Data Button */}
        <Col xs={6} sm={3} className="d-flex align-items-end">
          {/*<DownloadExcelKeywordTracking data={processedData} fileName="Processed_Keywords.xlsx" />*/}
        </Col>
      </Row>

      <Toaster />
    </Container>
  );
};

export default UploadExcelComponent;
