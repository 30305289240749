import React, { useState,useEffect } from 'react';
import { Modal, Spinner } from "react-bootstrap";
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { connect } from 'react-redux';

import {Header100} from "../../composant/Headers/Head100"
import TableComponentTop100 from "../../composant/table/TableComponentTop100"
import SearchComponentTop100 from "../../composant/RowCompoent/SearchComponentTop100"
import "../App.css";



function Top100Page({session}) {
  const [showModal, setShowModal] = useState(false); // Modal visibility
  const [state, setState] = useState(0); // Current state
 
  const [Data,SetData] = useState(); 
  const [searching, setSearching] = useState(false); 
  

  const handleClose = () => {
    setShowModal(false);
    setState(0); // Reset state when closing
  };

  const handleSearchRequest = (lang, country, keywords) => {
    
  };


  

  return (
    <div className="home" style={{ flexDirection: "column", backgroundColor: "#121212", color: "#ffffff" }}>
     
      <SearchComponentTop100 SetData = {SetData} Data={Data} searching={searching} setSearching={setSearching} session={session} />
      <TableComponentTop100 data={Data} />
      
      
    </div>
  );
}


const mapStateToProps = (state) => ({

  session: state.session,
});
export default connect(mapStateToProps)(Top100Page);