import React from "react";
import { Button } from "react-bootstrap";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { FaDownload } from "react-icons/fa"; // Importing download icon

const DownloadExcelDomain = ({ data, fileName = "domain_keywords.xlsx" }) => {
  const handleDownload = () => {
    if (!data || data.length === 0) {
      alert("لا يوجد بيانات لتنزيلها");
      return;
    }

    // Ensure `data` is a flat array (handle nested lists)
    const flatData = Array.isArray(data) && Array.isArray(data[0]) ? data[0] : data;

    // Create worksheet from JSON data
    const worksheet = XLSX.utils.json_to_sheet(
      flatData.map(({ Keyword, "Search Volume": searchVolume, "Competition Level": competition, CPC, "Rank Absolute": rank, "Page Title": title, "Page URL": url, Description, "Last Updated": lastUpdated }) => ({
        "الكلمة المفتاحية": Keyword,
        "عدد البحث": searchVolume,
        "المنافسة": competition,
        "متوسط سعر نقر الإعلان": CPC !== null ? CPC.toFixed(2) : "N/A",
        "الترتيب": rank,
        "عنوان الصفحة": title,
        "الرابط": url,
        "الوصف": Description,
        "آخر تحديث": lastUpdated,
      }))
    );

    // Create a workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Keywords Data");

    // Write the Excel file
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

    // Convert to Blob and trigger download
    const file = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(file, fileName);
  };

  return (
    <Button variant="success" onClick={handleDownload} className="mt-2" style={{ width: "45px", height: "45px" }}>
      <FaDownload size={20} /> {/* Download Icon */}
    </Button>
  );
};

export default DownloadExcelDomain;
