export function ExtractDomain(url) {
    try {
        const parsedUrl = new URL(url);
        let domain = parsedUrl.origin; // Keep protocol + domain
        
        let path = decodeURIComponent(parsedUrl.pathname.substring(1)); // Decode and remove leading '/'

        // Limit path to 10 characters and add '...' if it's longer
        if (path.length > 15) {
            path = path.substring(0, 15) + "...";
        }
 

        return { domain, path };
    } catch (error) {
        console.error("Invalid URL:", url);
        return null;
    }
}
