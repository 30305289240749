import React, { useState } from "react";
import { Container, Table, Pagination } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { ExtractDomain } from "../../lib/index";

const TableComponentDomain = ({ data = [] }) => {
  // Ensure `data` is properly structured as a flat array (handle list of lists)
  const flatData = Array.isArray(data) && Array.isArray(data[0]) ? data[0] : data;

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 1000; // Number of rows per page

  // Calculate total pages
  const totalPages = Math.ceil(flatData.length / itemsPerPage);

  // Get current page data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = flatData.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Container
      fluid
      className="p-3 d-flex flex-column align-items-center"
      style={{
        borderRadius: "10px",
        width: "100%",
        minHeight: "300px",
      }}
    >
      <div
        className="w-100"
        style={{
          maxWidth: "1400px",
          maxHeight: "80vh",
          overflowY: "auto",
          borderRadius: "10px",
          border: "1px solid #444",
        }}
      >
        <Table striped bordered hover variant="dark" responsive>
          <thead style={{ position: "sticky", top: "0", background: "#212429" }}>
            <tr>
              <th style={{ width: "5%" }}>#</th>
              <th style={{ width: "20%" }}>الرابط</th>
              <th style={{ width: "15%", whiteSpace: "normal", lineHeight: "1.2" }}>الكلمة <br /> المفتاحية</th>
              <th style={{ width: "10%" }}>عدد البحث</th>
              <th style={{ width: "8%", whiteSpace: "normal", lineHeight: "1.2" }}>
                متوسط سعر <br /> نقر الاعلان
              </th>
              <th style={{ width: "5%" }}>المنافسة</th>
              <th style={{ width: "5%" }}>الترتيب</th>
              <th style={{ width: "15%", whiteSpace: "normal", lineHeight: "1.2" }}>عنوان <br /> الصفحة</th>
              <th style={{ width: "30%" }}>الوصف</th>
              <th style={{ width: "10%" }}>آخر تحديث</th>
            </tr>
          </thead>
          <tbody>
            {currentData.length > 0 ? (
              currentData.map((item, index) => (
                <tr key={index}>
                  <td style={{ width: "5%" }}>{indexOfFirstItem + index + 1}</td>
                  <td style={{ width: "20%", direction: "ltr" }}>
                    {item["Page URL"] ? (
                      (() => {
                        const { domain, path } = ExtractDomain(item["Page URL"]);
                        return (
                          <a href={item["Page URL"]} target="_blank" rel="noopener noreferrer" className="text-light">
                            {domain}/{path}
                          </a>
                        );
                      })()
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td style={{ width: "15%", whiteSpace: "normal", lineHeight: "1.2" }}>{item.Keyword}</td>
                  <td style={{ width: "10%" }}>{item["Search Volume"]}</td>
                  <td style={{ width: "8%" }}>{item.CPC !== null ? item.CPC.toFixed(2) : "N/A"}</td>
                  <td style={{ width: "5%" }}>{item["Competition Level"]}</td>
                  <td style={{ width: "5%" }}>{item["Rank Absolute"]}</td>
                  <td style={{ width: "15%", whiteSpace: "normal", lineHeight: "1.2" }}>{item["Page Title"]}</td>
                  <td style={{ width: "20%" }}>{item.Description}</td>
                  <td style={{ width: "10%" }}>{item["Last Updated"]}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="10" className="text-center text-light">
                  لا توجد بيانات متاحة
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {/* Bootstrap Pagination */}
      {totalPages > 1 && (
        <Pagination className="mt-3">
          <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
          <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />

          {Array.from({ length: totalPages }, (_, i) => (
            <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={() => handlePageChange(i + 1)}>
              {i + 1}
            </Pagination.Item>
          ))}

          <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
          <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
      )}
    </Container>
  );
};

export default TableComponentDomain;
