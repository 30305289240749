import React, { useState, useEffect } from "react";
import { Button, Form, Container, Row, Col, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import toast, { Toaster } from "react-hot-toast";
import SelectCountry from "../../page/constants/Country";
import "../../page/App.css";
import axios from "axios";
import { DashboatTopDomain } from "../../page/constants/index";
import DownloadExcelDomain from "../SheetCompoent/DownloadExcelDomain";

const SearchComponentDomain = ({ SetData, Data, setSearching, searching, session }) => {
  const [lang, setLang] = useState("ar");
  const [country, setCountry] = useState("");
  const [keywordArray, setKeywordArray] = useState();
  const [selectedKeyword, setSelectedKeyword] = useState(""); // State for the new select field
  const [SelectDisable, setSelectDisable] = useState(false);
  const [FullData, setFullData] = useState(false);

  const handleSearch = () => {
    if (!lang || !country || keywordArray.length === 0) {
      toast.error("يرجى ملء جميع الحقول قبل البحث");
      return;
    }

    setSearching(true); // Set loading state to true

    axios
      .post(
        DashboatTopDomain,
        {
          domain: keywordArray,
          language_code: lang,
          location_code: country,
          deepth: 100,
        },
        {
          headers: {
            Authorization: `token ${session}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("data:", response.data.domain_keywords);
        SetData(response.data.domain_keywords);
        toast.success("تم البحث بنجاح");
      })
      .catch((error) => {
        toast.error("خطأ في البحث");
        console.error("Error:", error);
      })
      .finally(() => {
        setSearching(false); // Reset loading state after search completes
      });
  };

  return (
    <Container
      fluid
      className="p-3 d-flex justify-content-center align-items-center"
      style={{
        backgroundColor: "#212429",
        borderRadius: "10px",
        width: "100%",
        minHeight: "80px",
      }}
    >
      <Row className="w-100 d-flex align-items-flex-end g-2" style={{ maxWidth: "1100px" }}>
        {/* Language Select */}
        <Col xs={12} sm={2}>
          <Form.Group controlId="form-lang">
            <Form.Label className="text-light">اللغة</Form.Label>
            <Form.Select
              value={lang}
              onChange={(e) => setLang(e.target.value)}
              className="bg-dark text-light"
              style={{ height: "45px" }}
            >
              <option value="ar">العربية</option>
              <option value="en">الإنجليزية</option>
            </Form.Select>
          </Form.Group>
        </Col>

        {/* Country Select */}
        <Col xs={12} sm={2}>
          <Form.Group controlId="form-location">
            <Form.Label className="text-light">البلد</Form.Label>
            <div className="Big-select-country" style={{ height: "44px" }}>
              <SelectCountry setCountry={setCountry} country={country} type="write" Placeholder={"البلد"} />
            </div>
          </Form.Group>
        </Col>

        {/* Keywords Input Field */}
        <Col xs={12} sm={2}>
            <Form.Group controlId="form-keywords">
              <Form.Label className="text-light">النطاق</Form.Label>
              <Form.Control
                type="text"
                placeholder="أدخل الكلمات المفتاحية"
                value={keywordArray} // Store as a string
                onChange={(e) => setKeywordArray(e.target.value)} // Save the string directly
                className="bg-dark text-light"
                style={{ height: "45px" }}
              />
            </Form.Group>
          </Col>

        {/* Search Button */}
        <Col xs={12} sm={2} className="d-flex align-items-end" style={{ gap: "5px" }}>
          <Button
            variant="primary"
            onClick={handleSearch}
            className="w-100"
            style={{ height: "45px", fontSize: "18px" }}
            disabled={searching} // Disable button while searching
          >
            {searching ? (
              <>
                <Spinner animation="border" size="sm" role="status" className="me-2" />
              </>
            ) : (
              "بحث"
            )}
          </Button>
          <DownloadExcelDomain data={Data} filename={selectedKeyword} />
        </Col>
      </Row>

      <Toaster />
    </Container>
  );
};

export default SearchComponentDomain;
