import React, { useState } from 'react';

import Select from 'react-select';
import { connect } from "react-redux";
const country_list =[
    {"name": "Albania", "code": "AL"},
    {"name": "Åland Islands", "code": "AX"},
    {"name": "Algeria", "code": "DZ"},
    {"name": "American Samoa", "code": "AS"},
    {"name": "Andorra", "code": "AD"},
    {"name": "Angola", "code": "AO"},
    {"name": "Anguilla", "code": "AI"},
    {"name": "Antarctica", "code": "AQ"},
    {"name": "Antigua and Barbuda", "code": "AG"},
    {"name": "Argentina", "code": "AR"},
    {"name": "Armenia", "code": "AM"},
    {"name": "Aruba", "code": "AW"},
    {"name": "Australia", "code": "AU"},
    {"name": "Austria", "code": "AT"},
    {"name": "Azerbaijan", "code": "AZ"},
    {"name": "Bahamas (the)", "code": "BS"},
    {"name": "Bahrain", "code": "BH"},
    {"name": "Bangladesh", "code": "BD"},
    {"name": "Barbados", "code": "BB"},
    {"name": "Belarus", "code": "BY"},
    {"name": "Belgium", "code": "BE"},
    {"name": "Belize", "code": "BZ"},
    {"name": "Benin", "code": "BJ"},
    {"name": "Bermuda", "code": "BM"},
    {"name": "Bhutan", "code": "BT"},
    {"name": "Bolivia (Plurinational State of)", "code": "BO"},
    {"name": "Bonaire, Sint Eustatius and Saba", "code": "BQ"},
    {"name": "Bosnia and Herzegovina", "code": "BA"},
    {"name": "Botswana", "code": "BW"},
    {"name": "Bouvet Island", "code": "BV"},
    {"name": "Brazil", "code": "BR"},
    {"name": "British Indian Ocean Territory (the)", "code": "IO"},
    {"name": "Brunei Darussalam", "code": "BN"},
    {"name": "Bulgaria", "code": "BG"},
    {"name": "Burkina Faso", "code": "BF"},
    {"name": "Burundi", "code": "BI"},
    {"name": "Cabo Verde", "code": "CV"},
    {"name": "Cambodia", "code": "KH"},
    {"name": "Cameroon", "code": "CM"},
    {"name": "Canada", "code": "CA"},
    {"name": "Cayman Islands (the)", "code": "KY"},
    {"name": "Central African Republic (the)", "code": "CF"},
    {"name": "Chad", "code": "TD"},
    {"name": "Chile", "code": "CL"},
    {"name": "China", "code": "CN"},
    {"name": "Christmas Island", "code": "CX"},
    {"name": "Cocos (Keeling) Islands (the)", "code": "CC"},
    {"name": "Colombia", "code": "CO"},
    {"name": "Comoros (the)", "code": "KM"},
    {"name": "Congo (the Democratic Republic of the)", "code": "CD"},
    {"name": "Congo (the)", "code": "CG"},
    {"name": "Cook Islands (the)", "code": "CK"},
    {"name": "Costa Rica", "code": "CR"},
    {"name": "Croatia", "code": "HR"},
    {"name": "Cuba", "code": "CU"},
    {"name": "Curaçao", "code": "CW"},
    {"name": "Cyprus", "code": "CY"},
    {"name": "Czechia", "code": "CZ"},
    {"name": "Côte d'Ivoire", "code": "CI"},
    {"name": "Denmark", "code": "DK"},
    {"name": "Djibouti", "code": "DJ"},
    {"name": "Dominica", "code": "DM"},
    {"name": "Dominican Republic (the)", "code": "DO"},
    {"name": "Ecuador", "code": "EC"},
    {"name": "Egypt", "code": "EG"},
    {"name": "El Salvador", "code": "SV"},
    {"name": "Equatorial Guinea", "code": "GQ"},
    {"name": "Eritrea", "code": "ER"},
    {"name": "Estonia", "code": "EE"},
    {"name": "Eswatini", "code": "SZ"},
    {"name": "Ethiopia", "code": "ET"},
    {"name": "Falkland Islands (the) [Malvinas]", "code": "FK"},
    {"name": "Faroe Islands (the)", "code": "FO"},
    {"name": "Fiji", "code": "FJ"},
    {"name": "Finland", "code": "FI"},
    {"name": "France", "code": "FR"},
    {"name": "French Guiana", "code": "GF"},
    {"name": "French Polynesia", "code": "PF"},
    {"name": "French Southern Territories (the)", "code": "TF"},
    {"name": "Gabon", "code": "GA"},
    {"name": "Gambia (the)", "code": "GM"},
    {"name": "Georgia", "code": "GE"},
    {"name": "Germany", "code": "DE"},
    {"name": "Ghana", "code": "GH"},
    {"name": "Gibraltar", "code": "GI"},
    {"name": "Greece", "code": "GR"},
    {"name": "Greenland", "code": "GL"},
    {"name": "Grenada", "code": "GD"},
    {"name": "Guadeloupe", "code": "GP"},
    {"name": "Guam", "code": "GU"},
    {"name": "Guatemala", "code": "GT"},
    {"name": "Guernsey", "code": "GG"},
    {"name": "Guinea", "code": "GN"},
    {"name": "Guinea-Bissau", "code": "GW"},
    {"name": "Guyana", "code": "GY"},
    {"name": "Haiti", "code": "HT"},
    {"name": "Heard Island and McDonald Islands", "code": "HM"},
    {"name": "Holy See (the)", "code": "VA"},
    {"name": "Honduras", "code": "HN"},
    {"name": "Hong Kong", "code": "HK"},
    {"name": "Hungary", "code": "HU"},
    {"name": "Iceland", "code": "IS"},
    {"name": "India", "code": "IN"},
    {"name": "Indonesia", "code": "ID"},
    {"name": "Iran (Islamic Republic of)", "code": "IR"},
    {"name": "Iraq", "code": "IQ"},
    {"name": "Ireland", "code": "IE"},
    {"name": "Isle of Man", "code": "IM"},
    {"name": "Israel", "code": "IL"},
    {"name": "Italy", "code": "IT"},
    {"name": "Jamaica", "code": "JM"},
    {"name": "Japan", "code": "JP"},
    {"name": "Jersey", "code": "JE"},
    {"name": "Jordan", "code": "JO"},
    {"name": "Kazakhstan", "code": "KZ"},
    {"name": "Kenya", "code": "KE"},
    {"name": "Kiribati", "code": "KI"},
    {"name": "Korea (the Democratic People's Republic of)", "code": "KP"},
    {"name": "Korea (the Republic of)", "code": "KR"},
    {"name": "Kuwait", "code": "KW"},
    {"name": "Kyrgyzstan", "code": "KG"},
    {"name": "Lao People's Democratic Republic (the)", "code": "LA"},
    {"name": "Latvia", "code": "LV"},
    {"name": "Lebanon", "code": "LB"},
    {"name": "Lesotho", "code": "LS"},
    {"name": "Liberia", "code": "LR"},
    {"name": "Libya", "code": "LY"},
    {"name": "Liechtenstein", "code": "LI"},
    {"name": "Lithuania", "code": "LT"},
    {"name": "Luxembourg", "code": "LU"},
    {"name": "Macao", "code": "MO"},
    {"name": "Madagascar", "code": "MG"},
    {"name": "Malawi", "code": "MW"},
    {"name": "Malaysia", "code": "MY"},
    {"name": "Maldives", "code": "MV"},
    {"name": "Mali", "code": "ML"},
    {"name": "Malta", "code": "MT"},
    {"name": "Marshall Islands (the)", "code": "MH"},
    {"name": "Martinique", "code": "MQ"},
    {"name": "Mauritania", "code": "MR"},
    {"name": "Mauritius", "code": "MU"},
    {"name": "Mayotte", "code": "YT"},
    {"name": "Mexico", "code": "MX"},
    {"name": "Micronesia (Federated States of)", "code": "FM"},
    {"name": "Moldova (the Republic of)", "code": "MD"},
    {"name": "Monaco", "code": "MC"},
    {"name": "Mongolia", "code": "MN"},
    {"name": "Montenegro", "code": "ME"},
    {"name": "Montserrat", "code": "MS"},
    {"name": "Morocco", "code": "MA"},
    {"name": "Mozambique", "code": "MZ"},
    {"name": "Myanmar", "code": "MM"},
    {"name": "Namibia", "code": "NA"},
    {"name": "Nauru", "code": "NR"},
    {"name": "Nepal", "code": "NP"},
    {"name": "Netherlands (the)", "code": "NL"},
    {"name": "New Caledonia", "code": "NC"},
    {"name": "New Zealand", "code": "NZ"},
    {"name": "Nicaragua", "code": "NI"},
    {"name": "Niger (the)", "code": "NE"},
    {"name": "Nigeria", "code": "NG"},
    {"name": "Niue", "code": "NU"},
    {"name": "Norfolk Island", "code": "NF"},
    {"name": "Northern Mariana Islands (the)", "code": "MP"},
    {"name": "Norway", "code": "NO"},
    {"name": "Oman", "code": "OM"},
    {"name": "Pakistan", "code": "PK"},
    {"name": "Palau", "code": "PW"},
    {"name": "Palestine, State of", "code": "PS"},
    {"name": "Panama", "code": "PA"},
    {"name": "Papua New Guinea", "code": "PG"},
    {"name": "Paraguay", "code": "PY"},
    {"name": "Peru", "code": "PE"},
    {"name": "Philippines (the)", "code": "PH"},
    {"name": "Pitcairn", "code": "PN"},
    {"name": "Poland", "code": "PL"},
    {"name": "Portugal", "code": "PT"},
    {"name": "Puerto Rico", "code": "PR"},
    {"name": "Qatar", "code": "QA"},
    {"name": "Republic of North Macedonia", "code": "MK"},
    {"name": "Romania", "code": "RO"},
    {"name": "Russian Federation (the)", "code": "RU"},
    {"name": "Rwanda", "code": "RW"},
    {"name": "Réunion", "code": "RE"},
    {"name": "Saint Barthélemy", "code": "BL"},
    {"name": "Saint Helena, Ascension and Tristan da Cunha", "code": "SH"},
    {"name": "Saint Kitts and Nevis", "code": "KN"},
    {"name": "Saint Lucia", "code": "LC"},
    {"name": "Saint Martin (French part)", "code": "MF"},
    {"name": "Saint Pierre and Miquelon", "code": "PM"},
    {"name": "Saint Vincent and the Grenadines", "code": "VC"},
    {"name": "Samoa", "code": "WS"},
    {"name": "San Marino", "code": "SM"},
    {"name": "Sao Tome and Principe", "code": "ST"},
    {"name": "Saudi Arabia", "code": "SA"},
    {"name": "Senegal", "code": "SN"},
    {"name": "Serbia", "code": "RS"},
    {"name": "Seychelles", "code": "SC"},
    {"name": "Sierra Leone", "code": "SL"},
    {"name": "Singapore", "code": "SG"},
    {"name": "Sint Maarten (Dutch part)", "code": "SX"},
    {"name": "Slovakia", "code": "SK"},
    {"name": "Slovenia", "code": "SI"},
    {"name": "Solomon Islands", "code": "SB"},
    {"name": "Somalia", "code": "SO"},
    {"name": "South Africa", "code": "ZA"},
    {"name": "South Georgia and the South Sandwich Islands", "code": "GS"},
    {"name": "South Sudan", "code": "SS"},
    {"name": "Spain", "code": "ES"},
    {"name": "Sri Lanka", "code": "LK"},
    {"name": "Sudan (the)", "code": "SD"},
    {"name": "Suriname", "code": "SR"},
    {"name": "Svalbard and Jan Mayen", "code": "SJ"},
    {"name": "Sweden", "code": "SE"},
    {"name": "Switzerland", "code": "CH"},
    {"name": "Syrian Arab Republic", "code": "SY"},
    {"name": "Taiwan (Province of China)", "code": "TW"},
    {"name": "Tajikistan", "code": "TJ"},
    {"name": "Tanzania, United Republic of", "code": "TZ"},
    {"name": "Thailand", "code": "TH"},
    {"name": "Timor-Leste", "code": "TL"},
    {"name": "Togo", "code": "TG"},
    {"name": "Tokelau", "code": "TK"},
    {"name": "Tonga", "code": "TO"},
    {"name": "Trinidad and Tobago", "code": "TT"},
    {"name": "Tunisia", "code": "TN"},
    {"name": "Turkey", "code": "TR"},
    {"name": "Turkmenistan", "code": "TM"},
    {"name": "Turks and Caicos Islands (the)", "code": "TC"},
    {"name": "Tuvalu", "code": "TV"},
    {"name": "Uganda", "code": "UG"},
    {"name": "Ukraine", "code": "UA"},
    {"name": "United Arab Emirates (the)", "code": "AE"},
    {"name": "United Kingdom of Great Britain and Northern Ireland (the)", "code": "GB"},
    {"name": "United States Minor Outlying Islands (the)", "code": "UM"},
    {"name": "United States of America (the)", "code": "US"},
    {"name": "Uruguay", "code": "UY"},
    {"name": "Uzbekistan", "code": "UZ"},
    {"name": "Vanuatu", "code": "VU"},
    {"name": "Venezuela (Bolivarian Republic of)", "code": "VE"},
    {"name": "Viet Nam", "code": "VN"},
    {"name": "Virgin Islands (British)", "code": "VG"},
    {"name": "Virgin Islands (U.S.)", "code": "VI"},
    {"name": "Wallis and Futuna", "code": "WF"},
    {"name": "Western Sahara", "code": "EH"},
    {"name": "Yemen", "code": "YE"},
    {"name": "Zambia", "code": "ZM"},
    {"name": "Zimbabwe", "code": "ZW"}
  ]
const country_list_data_for_seo = [

      {"name": "Afghanistan", "code": 2004},
      {"name": "Albania", "code": 2008},
      {"name": "Antarctica", "code": 2010},
      {"name": "Algeria", "code": 2012},
      {"name": "American Samoa", "code": 2016},
      {"name": "Andorra", "code": 2020},
      {"name": "Angola", "code": 2024},
      {"name": "Antigua and Barbuda", "code": 2028},
      {"name": "Azerbaijan", "code": 2031},
      {"name": "Argentina", "code": 2032},
      {"name": "Australia", "code": 2036},
      {"name": "Austria", "code": 2040},
      {"name": "The Bahamas", "code": 2044},
      {"name": "Bahrain", "code": 2048},
      {"name": "Bangladesh", "code": 2050},
      {"name": "Armenia", "code": 2051},
      {"name": "Barbados", "code": 2052},
      {"name": "Belgium", "code": 2056},
      {"name": "Bermuda", "code": 2060},
      {"name": "Bhutan", "code": 2064},
      {"name": "Bolivia", "code": 2068},
      {"name": "Bosnia and Herzegovina", "code": 2070},
      {"name": "Botswana", "code": 2072},
      {"name": "Bouvet Island", "code": 2074},
      {"name": "Brazil", "code": 2076},
      {"name": "Belize", "code": 2084},
      {"name": "British Indian Ocean Territory", "code": 2086},
      {"name": "Solomon Islands", "code": 2090},
      {"name": "British Virgin Islands", "code": 2092},
      {"name": "Brunei", "code": 2096},
      {"name": "Bulgaria", "code": 2100},
      {"name": "Myanmar (Burma)", "code": 2104},
      {"name": "Burundi", "code": 2108},
      {"name": "Cambodia", "code": 2116},
      {"name": "Cameroon", "code": 2120},
      {"name": "Canada", "code": 2124},
      {"name": "Cabo Verde", "code": 2132},
      {"name": "Cayman Islands", "code": 2136},
      {"name": "Central African Republic", "code": 2140},
      {"name": "Sri Lanka", "code": 2144},
      {"name": "Chad", "code": 2148},
      {"name": "Chile", "code": 2152},
      {"name": "China", "code": 2156},
      {"name": "Taiwan", "code": 2158},
      {"name": "Christmas Island", "code": 2162},
      {"name": "Cocos (Keeling) Islands", "code": 2166},
      {"name": "Colombia", "code": 2170},
      {"name": "Comoros", "code": 2174},
      {"name": "Mayotte", "code": 2175},
      {"name": "Republic of the Congo", "code": 2178},
      {"name": "Democratic Republic of the Congo", "code": 2180},
      {"name": "Cook Islands", "code": 2184},
      {"name": "Costa Rica", "code": 2188},
      {"name": "Croatia", "code": 2191},
      {"name": "Cyprus", "code": 2196},
      {"name": "Czechia", "code": 2203},
      {"name": "Benin", "code": 2204},
      {"name": "Denmark", "code": 2208},
      {"name": "Dominica", "code": 2212},
      {"name": "Dominican Republic", "code": 2214},
      {"name": "Ecuador", "code": 2218},
      {"name": "El Salvador", "code": 2222},
      {"name": "Equatorial Guinea", "code": 2226},
      {"name": "Ethiopia", "code": 2231},
      {"name": "Eritrea", "code": 2232},
      {"name": "Estonia", "code": 2233},
      {"name": "Faroe Islands", "code": 2234},
      {"name": "Falkland Islands (Islas Malvinas)", "code": 2238},
      {"name": "South Georgia and the South Sandwich Islands", "code": 2239},
      {"name": "Fiji", "code": 2242},
      {"name": "Finland", "code": 2246},
      {"name": "France", "code": 2250},
      {"name": "French Guiana", "code": 2254},
      {"name": "French Polynesia", "code": 2258},
      {"name": "French Southern and Antarctic Lands", "code": 2260},
      {"name": "Djibouti", "code": 2262},
      {"name": "Gabon", "code": 2266},
      {"name": "Georgia", "code": 2268},
      {"name": "The Gambia", "code": 2270},
      {"name": "Palestine", "code": 2275},
      {"name": "Germany", "code": 2276},
      {"name": "Ghana", "code": 2288},
      {"name": "Gibraltar", "code": 2292},
      {"name": "Kiribati", "code": 2296},
      {"name": "Greece", "code": 2300},
      {"name": "Greenland", "code": 2304},
      {"name": "Grenada", "code": 2308},
      {"name": "Guadeloupe", "code": 2312},
      {"name": "Guam", "code": 2316},
      {"name": "Guatemala", "code": 2320},
      {"name": "Guinea", "code": 2324},
      {"name": "Guyana", "code": 2328},
      {"name": "Haiti", "code": 2332},
      {"name": "Heard Island and McDonald Islands", "code": 2334},
      {"name": "Vatican City", "code": 2336},
      {"name": "Honduras", "code": 2340},
      {"name": "Hong Kong", "code": 2344},
      {"name": "Hungary", "code": 2348},
      {"name": "Iceland", "code": 2352},
      {"name": "India", "code": 2356},
      {"name": "Indonesia", "code": 2360},
      {"name": "Iraq", "code": 2368},
      {"name": "Ireland", "code": 2372},
      {"name": "Israel", "code": 2376},
      {"name": "Italy", "code": 2380},
      {"name": "Cote d'Ivoire", "code": 2384},
      {"name": "Jamaica", "code": 2388},
      {"name": "Japan", "code": 2392},
      {"name": "Kazakhstan", "code": 2398},
      {"name": "Jordan", "code": 2400},
      {"name": "Kenya", "code": 2404},
      {"name": "South Korea", "code": 2410},
      {"name": "Kuwait", "code": 2414},
      {"name": "Kyrgyzstan", "code": 2417},
      {"name": "Laos", "code": 2418},
      {"name": "Lebanon", "code": 2422},
      {"name": "Lesotho", "code": 2426},
      {"name": "Latvia", "code": 2428},
      {"name": "Liberia", "code": 2430},
      {"name": "Libya", "code": 2434},
      {"name": "Liechtenstein", "code": 2438},
      {"name": "Lithuania", "code": 2440},
      {"name": "Luxembourg", "code": 2442},
      {"name": "Macao", "code": 2446},
      {"name": "Madagascar", "code": 2450},
      {"name": "Malawi", "code": 2454},
      {"name": "Malaysia", "code": 2458},
      {"name": "Maldives", "code": 2462},
      {"name": "Mali", "code": 2466},
      {"name": "Malta", "code": 2470},
      {"name": "Martinique","code": 2474},
      {"name": "Mauritania", "code": 2478},
      {"name": "Mauritius", "code": 2480},
      {"name": "Mexico", "code": 2484},
      {"name": "Monaco", "code": 2492},
      {"name": "Mongolia", "code": 2496},
      {"name": "Moldova", "code": 2498},
      {"name": "Montenegro", "code": 2499},
      {"name": "Montserrat", "code": 2500},
      {"name": "Morocco", "code": 2504},
      {"name": "Mozambique", "code": 2508},
      {"name": "Oman", "code": 2512},
      {"name": "Namibia", "code": 2516},
      {"name": "Nauru", "code": 2520},
      {"name": "Nepal", "code": 2524},
      {"name": "Netherlands", "code": 2528},
      {"name": "Curacao", "code": 2531},
      {"name": "Aruba", "code": 2533},
      {"name": "Sint Maarten", "code": 2534},
      {"name": "Caribbean Netherlands", "code": 2535},
      {"name": "New Caledonia", "code": 2540},
      {"name": "Vanuatu", "code": 2548},
      {"name": "New Zealand", "code": 2554},
      {"name": "Nicaragua", "code": 2558},
      {"name": "Niger", "code": 2562},
      {"name": "Nigeria", "code": 2566},
      {"name": "Niue", "code": 2570},
      {"name": "Norfolk Island", "code": 2574},
      {"name": "Norway", "code": 2578},
      {"name": "Northern Mariana Islands", "code": 2580},
      {"name": "United States Minor Outlying Islands", "code": 2581},
      {"name": "Micronesia", "code": 2583},
      {"name": "Marshall Islands", "code": 2584},
      {"name": "Palau", "code": 2585},
      {"name": "Pakistan", "code": 2586},
      {"name": "Panama", "code": 2591},
      {"name": "Papua New Guinea", "code": 2598},
      {"name": "Paraguay", "code": 2600},
      {"name": "Peru", "code": 2604},
      {"name": "Philippines", "code": 2608},
      {"name": "Pitcairn Islands", "code": 2612},
      {"name": "Poland", "code": 2616},
      {"name": "Portugal", "code": 2620},
      {"name": "Guinea-Bissau", "code": 2624},
      {"name": "Timor-Leste", "code": 2626},
      {"name": "Puerto Rico", "code": 2630},
      {"name": "Qatar", "code": 2634},
      {"name": "Reunion", "code": 2638},
      {"name": "Romania", "code": 2642},
      {"name": "Rwanda", "code": 2646},
      {"name": "Saint Helena, Ascension and Tristan da Cunha", "code": 2654},
      {"name": "Saint Kitts and Nevis", "code": 2659},
      {"name": "Anguilla", "code": 2660},
      {"name": "Saint Lucia", "code": 2662},
      {"name": "Saint Pierre and Miquelon", "code": 2666},
      {"name": "Saint Vincent and the Grenadines", "code": 2670},
      {"name": "San Marino", "code": 2674},
      {"name": "Sao Tome and Principe", "code": 2678},
      {"name": "Saudi Arabia", "code": 2682},
      {"name": "Senegal", "code": 2686},
      {"name": "Serbia", "code": 2688},
      {"name": "Seychelles", "code": 2690},
      {"name": "Sierra Leone", "code": 2694},
      {"name": "Singapore", "code": 2702},
      {"name": "Slovakia", "code": 2703},
      {"name": "Vietnam", "code": 2704},
      {"name": "Slovenia", "code": 2705},
      {"name": "Somalia", "code": 2706},
      {"name": "South Africa", "code": 2710},
      {"name": "Zimbabwe", "code": 2716},
      {"name": "Spain", "code": 2724},
      {"name": "Western Sahara", "code": 2732},
      {"name": "Suriname", "code": 2740},
      {"name": "Svalbard and Jan Mayen", "code": 2744},
      {"name": "Eswatini", "code": 2748},
      {"name": "Sweden", "code": 2752},
      {"name": "Switzerland", "code": 2756},
      {"name": "Tajikistan", "code": 2762},
      {"name": "Thailand", "code": 2764},
      {"name": "Togo", "code": 2768},
      {"name": "Tokelau", "code": 2772},
      {"name": "Tonga", "code": 2776},
      {"name": "Trinidad and Tobago", "code": 2780},
      {"name": "United Arab Emirates", "code": 2784},
      {"name": "Tunisia", "code": 2788},
      {"name": "Turkiye", "code": 2792},
      {"name": "Turkmenistan", "code": 2795},
      {"name": "Turks and Caicos Islands", "code": 2796},
      {"name": "Tuvalu", "code": 2798},
      {"name": "Uganda", "code": 2800},
      {"name": "Ukraine", "code": 2804},
      {"name": "North Macedonia", "code": 2807},
      {"name": "Egypt", "code": 2818},
      {"name": "United Kingdom", "code": 2826},
      {"name": "Guernsey", "code": 2831},
      {"name": "Jersey", "code": 2832},
      {"name": "Isle of Man", "code": 2833},
      {"name": "Tanzania", "code": 2834},
      {"name": "United States", "code": 2840},
      {"name": "U.S. Virgin Islands", "code": 2850},
      {"name": "Burkina Faso", "code": 2854},
      {"name": "Uruguay", "code": 2858},
      {"name": "Uzbekistan", "code": 2860},
      {"name": "Venezuela", "code": 2862},
      {"name": "Wallis and Futuna", "code": 2876},
      {"name": "Samoa", "code": 2882},
      {"name": "Yemen", "code": 2887},
      {"name": "Zambia", "code": 2894},
      {"name": "Kosovo", "code": 2900}
  
  
  ]
  const mapStateToProps = (state) => {
    return {
      SerpState: state.SerpState
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
     
    };
  };
   const SelectCountry = ({ setCountry,type,Placeholder,SerpState }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const handleSelectChange = (selectedOption) => {
      setSelectedOption(selectedOption);
      setCountry(selectedOption ? selectedOption.value : null);
    };
  
    const countryOptions = (SerpState === 1 ? country_list_data_for_seo : country_list).map(country => ({
      value: country.code,
      label: country.name,
      backgroundColor: "black", // Add backgroundColor property
    }));
    var customStyles={}
    if(type ==="analyse"){
      customStyles={
        control: (styles) => ({ ...styles, backgroundColor: 'white',fontSize:"11px",color:"black" }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected
            ? '#3D2E4F'
            : isFocused
            ? '#3D2E4F'
            : null,
          color: isDisabled ? '#3D2E4F' : isSelected ? 'white' : 'black',
          cursor: isDisabled ? 'not-allowed' : 'default',
          fontSize:"10px",
    
          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled
              ? isSelected
                ? "white"
                : 'white'
              : null,
          },
        }),
      };
    }
    else{
     customStyles = {
      control: (styles) => ({ ...styles, backgroundColor: 'black',fontSize:"11px",color:"white" }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? data.backgroundColor
          : isFocused
          ? '#3D2E4F'
          : null,
        color: isDisabled ? 'white' : isSelected ? 'white' : 'white',
        cursor: isDisabled ? 'not-allowed' : 'default',
        fontSize:"10px",
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.backgroundColor
              : 'white'
            : null,
        },
      }),
    };
  }
    return (
      <Select
        className="basic-single"
        classNamePrefix="select"
        value={selectedOption}
        onChange={handleSelectChange}
        options={countryOptions}
        isSearchable
        placeholder={Placeholder}
        styles={customStyles}
      />
    );
  };

  export default connect(mapStateToProps, mapDispatchToProps)(SelectCountry);