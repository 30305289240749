import React from "react";
import { Container, Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { ExtractDomain } from "../../lib/index";
const TableComponentTop100 = ({ data = [] }) => {
  console.log("data",data)
  return (
    <Container
      fluid
      className="p-3 d-flex justify-content-center align-items-center"
      style={{
        borderRadius: "10px",
        width: "100%",
        minHeight: "300px", // Increased minimum height
      }}
    >
      <div
        className="w-100"
        style={{
          maxWidth: "1400px",
          maxHeight: "80vh", // Set a max height for scroll
          overflowY: "auto", // Enables vertical scrolling
          borderRadius: "10px",
          border: "1px solid #444",
        }}
      >
        <Table striped bordered hover variant="dark" responsive>
          <thead style={{ position: "sticky", top: "0", background: "#212429" }}>
            <tr>
              <th style={{ width: "5%" }}>#</th> {/* Made index column smaller */}
              <th> الرابط</th>
              <th>العنوان</th>
              <th>الوصف</th>
              
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((item, index) => (
                <tr key={index}>
                  <td style={{ width: "5%" }}>{index + 1}</td> {/* Applied width to data cell */}
                  <td style={{   direction: "ltr"}}  >
                                    {item.url ? (
                                            (() => {
                                              const { domain, path } = ExtractDomain(item.url); // Extract domain & path
                                       
                                              return (
                                                <a href={item.url} target="_blank" rel="noopener noreferrer" className="text-light" >
                                                  {domain}/{path}
                                                </a>
                                              );
                                            })()
                                          ) : (
                                            "N/A"
                                          )}
                                    </td>
                  <td>{item.title}</td>
                  <td>{item.description}</td>
                  
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center text-light">
                  لا توجد بيانات متاحة
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </Container>
  );
};

export default TableComponentTop100;
