import React, { useState,useEffect } from 'react';
import { Modal, Spinner } from "react-bootstrap";
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { connect } from 'react-redux';

import UploadExcelComponent from "../../composant/RowCompoent/UploadExcelComponent"

import "../App.css";



function TrackingKeywoard({session}) {
  const [showModal, setShowModal] = useState(false); // Modal visibility
  const [state, setState] = useState(0); // Current state
 
  const [Data,SetData] = useState(); 
  const [searching, setSearching] = useState(false); 
  

  const handleClose = () => {
    setShowModal(false);
    setState(0); // Reset state when closing
  };

  const handleSearchRequest = (lang, country, keywords) => {
    
  };


  

  return (
    <div className="home" style={{ flexDirection: "column", backgroundColor: "#121212", color: "#ffffff" }}>
     
     <UploadExcelComponent session={session}/>
      
      
    </div>
  );
}


const mapStateToProps = (state) => ({

  session: state.session,
});
export default connect(mapStateToProps)(TrackingKeywoard);