import React from "react";
import { Button } from "react-bootstrap";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { FaDownload } from "react-icons/fa"; // Importing download icon

const DownloadExcelTop100 = ({ data, fileName = "data.xlsx" }) => {
  const handleDownload = () => {
    
    if (!data || data.length === 0) {
      alert("لا يوجد بيانات لتنزيلها");
      return;
    }

    // Create worksheet from JSON data
    const worksheet = XLSX.utils.json_to_sheet(
      data.map(({ index, title, description, url }) => ({
        "#": index + 1, // Convert index to start from 1
        "العنوان": title,
        "الوصف": description,
        "الرابط": url,
      }))
    );

    // Create a workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the Excel file
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

    // Convert to Blob and trigger download
    const file = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(file, fileName);
  };

  return (
    <Button variant="success" onClick={handleDownload} className="mt-2" style={{ width: "45px", height: "45px" }}>
      <FaDownload size={20} /> {/* Download Icon */}
    </Button>
  );
};

export default DownloadExcelTop100;
